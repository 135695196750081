import React from "react"
import { css, keyframes } from "styled-components"

const pulse = keyframes`
  from {
    transform: scale(0.25);
    opacity: 1.0;
  }
  95% {
    transform: scale(1.3);
    opacity: 0;
  }
  to {
    transform: scale(0.3);
    opacity: 0;
  }
`

const CurrentPositionMarker = ({
  anchor,
  left,
  top,
  pixelToLatLng,
  mapState: { center, zoom, bounds },
}) => {
  const currentPosition = pixelToLatLng([left, top], center, zoom)
  if (
    currentPosition[0] > bounds.ne[0] ||
    currentPosition[0] < bounds.sw[0] ||
    currentPosition[1] > bounds.ne[1] ||
    currentPosition[1] < bounds.sw[1]
  ) {
    return null
  }
  return (
    <div
      style={{ top, left }}
      css={css`
        position: absolute;
        transform: translate(-50%, -50%) scale(${Math.max(zoom / 20, 0.5)});
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      `}
    >
      <div
        css={css`
          width: 50px;
          height: 50px;
          background: transparent;
          border: 5px solid #00d9ed; /*  #0066ff; */
          position: absolute;
          border-radius: 50%;
          animation: ${pulse} 2s infinite;
        `}
      />
      <div
        anchor={anchor}
        css={css`
          width: 30px;
          height: 30px;
          background: #00d9ed;
          border-radius: 50%;
          z-index: 10;
          box-shadow: 0px 1px 11px #007984c7;
        `}
      />
    </div>
  )
}

export default CurrentPositionMarker
