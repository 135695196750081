const MAPTILER_ACCESS_TOKEN = process.env.GATSBY_MAPBOX_API_KEY
const MAP_ID = "streets"

export const mapSelect = (lat, long) => {
  if (
    navigator.platform.includes("iPhone") ||
    navigator.platform.includes("iPad") ||
    navigator.platform.includes("iPod") ||
    navigator.platform.includes("Mac")
  ) {
    window.open(`http://maps.apple.com/maps?q=${lat},${long}`)
  } else {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`
    )
  }
}

export const mapTilerProvider = (x, y, z, dpr) =>
  `https://api.maptiler.com/maps/${MAP_ID}/256/${z}/${x}/${y}${
    dpr >= 2 ? "@2x" : ""
  }.png?key=${MAPTILER_ACCESS_TOKEN}`
