import React from "react"
import { css } from "styled-components"

import { mapSelect } from "../utils/map"

const MapOverlay = ({ anchor, payload: { image, title, subtitle } }) => (
  <div
    anchor={anchor}
    className="pigeon-drag-block pigeon-click-block"
    css={css`
      background: white;
      width: 75%;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      top: 20px;
      min-height: 100px;
      border-radius: 9px;
      box-shadow: 0px 3px 11px #00000015;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      z-index: 20;
      max-width: 500px;
    `}
  >
    <img
      src={image}
      alt={title}
      loading="lazy"
      css={css`
        width: 100%;
      `}
    />
    <button
      css={css`
        padding: 10px;
        width: 100%;
        color: #707070;
        cursor: pointer;
        outline: 0;
      `}
      onClick={() => mapSelect(anchor[0], anchor[1])}
    >
      <div
        css={css`
          font-size: 14px;
          font-weight: bold;
        `}
      >
        {title}
      </div>
      <div
        css={css`
          font-size: 11px;
        `}
      >
        {subtitle}
      </div>
    </button>
  </div>
)

export default MapOverlay
