import { useState, useEffect, useRef } from "react"

export const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
}

export const useCurrentLocation = (options = {}) => {
  const [error, setError] = useState()
  const [location, setLocation] = useState()
  const locationWatchId = useRef(null)

  const cancelLocation = () => {
    if (locationWatchId.current && navigator.geolocation) {
      navigator.geolocation.clearWatch(locationWatchId.current)
    }
  }

  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocation not available")
      return
    } else {
      locationWatchId.current = navigator.geolocation.watchPosition(
        handleSuccess,
        handleError,
        options
      )
    }

    return cancelLocation
  }, [options])

  const handleSuccess = position => {
    const { latitude, longitude } = position.coords

    setLocation({
      lat: latitude,
      long: longitude,
    })
  }

  const handleError = error => {
    setError(error.message)
  }

  return { location, cancelLocation, error }
}
