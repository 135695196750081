import React, { useState, useEffect } from "react"
import { css } from "styled-components"
import { Map, Marker } from "pigeon-maps"

import ContentLayout from "../components/ContentLayout"
import MapOverlay from "../components/MapOverlay"
import CurrentPositionMarker from "../components/CurrentPositionMarker"

import {
  useCurrentLocation,
  geolocationOptions,
} from "../hooks/useCurrentLocation"

import Icon from "../svg/menu/hol_vagyok.svg"

import markers from "../sample/markers.json"
import { mapTilerProvider } from "../utils/map"

const HolVagyokPage = () => {
  const [marker, setMarker] = useState()
  const { location, cancelLocation } = useCurrentLocation(geolocationOptions)

  useEffect(() => {
    if (!location) return

    setTimeout(() => {
      cancelLocation()
    }, 3000)

    return () => cancelLocation()
  }, [location, cancelLocation])

  const selectMarker = ({ anchor, payload }) => {
    setMarker({ anchor, payload })
  }

  const deselectMarker = () => {
    setMarker()
  }

  return (
    <ContentLayout
      icon={Icon}
      title="Hol vagyok?"
      full
      css={css`
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      `}
    >
      <div
        css={css`
          height: 100%;
          width: 100%;
          flex: 1;
          display: flex;
          position: absolute;
        `}
      >
        <Map
          provider={mapTilerProvider}
          defaultCenter={[47.6795, 16.5745]}
          defaultZoom={15.5}
          zoomSnap={false}
          dprs={[1, 2]}
          onClick={deselectMarker}
        >
          {markers.map(({ id, position, data }) => (
            <Marker
              key={id}
              anchor={position}
              payload={data}
              width={40}
              height={50}
              color="#FFB700"
              onClick={selectMarker}
              css={css`
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-touch-callout: none;
              `}
            />
          ))}
          {marker && (
            <MapOverlay anchor={marker.anchor} payload={marker.payload} />
          )}
          {location && (
            <CurrentPositionMarker anchor={[location.lat, location.long]} />
          )}
        </Map>
      </div>
    </ContentLayout>
  )
}

export default HolVagyokPage
